<template>
  <div
    class="container is-fluid"
    v-if="$parent.are_jobs_loaded && proj_trc_jobs_got"
  >
    <!-- Warehouse tabs -->
    <div class="columns mt-0 pt-2">
      <div class="column pt-0 px-0 is-flex" style="">
        <div
          v-if="!isFixed && selected_warehouse_key"
          class="is-flex is-flex-direction-column pr-2 has-text-centered"
          style="border-bottom: solid 1px #dbdbdb"
        >
          <label class="switch">
            <input
              type="checkbox"
              v-model="direction"
              @change="select_warehouse(selected_warehouse_key)"
            />
            <!-- in change -> 1 or 0 -->
            <span class="slider round"></span>
          </label>
          <small>
            {{ direction ? $t("delivery") : $t("return") }}
          </small>
          <!-- {{direction}} -->
        </div>
        <div
          class="is-flex is-flex-direction-column is-size-7 pl-2 has-text-weight-semibold has-text-grey-light"
          style="border-bottom: solid 1px #dbdbdb; min-width: fit-content"
        >
          <span v-if="$parent.event_details.Address_Customer">
            {{ $parent.event_details.Address_Customer.Street }},
            {{ $parent.event_details.Address_Customer.Zip }}
            <br />
            {{ $parent.event_details.Address_Customer.City }},
            <span v-if="$parent.event_details.Address_Customer.Country">
              {{ $parent.event_details.Address_Customer.Country.Caption }}</span
            >
          </span>
          <!-- <pre>
                            {{$parent.event_details.Address_Customer}}
                        </pre> -->
        </div>

        <div
          class="tabs is-small is-centered is-boxed"
          style="width: 100%"
          v-if="direction"
        >
          <ul class="horisontal-scroll planning-warehouses-tabs">
            <li
              v-for="key in Object.keys(warehouses)"
              :key="key"
              :class="[
                selected_warehouse.ID == warehouses[key].ID ? 'is-active' : '',
              ]"
              class="horisontal-item"
            >
              <a @click="select_warehouse(key)">
                <!-- 
										!warehouses[key].jobs means is a transit 
									-->
                <p
                  class="is-uppercase"
                  v-html="
                    '<small class=\'is-block is-capitalized \'>' +
                    (!warehouses[key].jobs
                      ? warehouses[key].Caption.replace(' - ', '</small>')
                      : '<i class=\'fas fa-project-diagram mr-2 is-cobalt-text-1\'></i>' +
                        '<small class=\'is-cobalt-text-1\'>TRANSIT</small><br>' +
                        '<span class=\'is-block is-uppercase \'>' +
                        warehouses[key].Caption +
                        '</span>')
                  "
                ></p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Select warehouse -->
    <div v-if="selected_warehouse.ID" class="columns">
      <div v-if="show_materials_pannel" class="column is-3 p-0">
        <div :class="{ fixed: isFixed }">
          <div
            class="tabs is-centered mb-0"
            v-if="current_warehouse_date == -1"
          >
            <ul>
              <li>
                <div
                  v-if="isFixed && selected_warehouse_key"
                  class="is-flex pr-2 has-text-centered"
                  style="align-items: center"
                >
                  <label class="switch">
                    <input
                      type="checkbox"
                      v-model="direction"
                      @change="select_warehouse(selected_warehouse_key)"
                    />
                    <!-- in change -> 1 or 0 -->
                    <span class="slider round"></span>
                  </label>
                  <small class="m-2">
                    {{ direction ? $t("delivery") : $t("return") }}
                  </small>
                  <!-- {{direction}} -->
                </div>
              </li>
              <li
                @click.prevent="show_all_materials = false"
                :class="{ 'is-active': !show_all_materials }"
              >
                <a v-if="direction">{{ $t("warehouse_materials") }}</a>
                <a v-else>{{ $t("onsite_materials") }}</a>
              </li>
              <li
                @click.prevent="show_all_materials = true"
                :class="{ 'is-active': show_all_materials }"
              >
                <a>{{ $t("priject_materials") }}</a>
              </li>
            </ul>
          </div>
          <!-- LIST OF THE MATERIALS OF A WAREHOUSE IN DELIVERY OTHERWISE OF ALL OF THEM AS TRANSITS ONES -->
          <div v-if="!show_all_materials && current_warehouse_date == -1">
            <div
              class="notification is-info is-dark p-2 my-2 is-size-7 has-text-weight-bold"
              v-if="direction"
            >
              {{ selected_warehouse.Caption }}
            </div>
            <warehouse-materials
              :selected_warehouse_jobs_materials="
                selected_warehouse_jobs_materials
              "
            ></warehouse-materials>
          </div>
          <div v-else>
            <div
              class="notification is-info has-text-weight-bold p-2 my-2 is-size-7"
            >
              {{ $t("all_materials_of_project") }}
            </div>
            <!-- Materials of selected warehouse -->
            <div class="deliver-plan-right">
              <table class="table is-size-7 is-fullwidth">
                <thead>
                  <tr>
                    <th>{{ $t("material_planning") }}</th>
                    <th>{{ $t("dates_planning") }}</th>
                    <th>{{ $t("quantity_planning") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(material, index) in materials_1"
                    :key="index"
                    class="has-border"
                    :class="[
                      material.warehouse_id == selected_warehouse.ID
                        ? 'has-text-weight-bold'
                        : 'has-text-grey-light',
                    ]"
                  >
                    <td>
                      <small class="has-text-grey-light" style="font-size: 9px">
                        {{ index + 1 }}.
                      </small>
                      {{ material.Caption }}
                    </td>
                    <td style="white-space: pre">
                      <p v-if="material.dates" style="font-size: 9px">
                        {{ material.dates[0] | customdate }} <br />
                        {{ material.dates[1] | customdate }}
                      </p>
                    </td>
                    <td>
                      <span v-if="material.Qty > 0">{{ material.Qty }}</span>
                      <small v-else class="has-text-success">
                        {{ material.QtyWas }} <i class="fa fa-check fa-sm"></i>
                      </small>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div class="column pt-2">
        <!-- Header warehouse tabs  -->
        <p class="has-text-centered pb-0 pl-2">
          <span
            @click.prevent="show_trucks_pannel = !show_trucks_pannel"
            class="is-pulled-right is-small"
          >
            <i class="fas fa-truck"></i>
          </span>
        </p>
        <delivery-jobs></delivery-jobs>
      </div>
      <div v-if="show_trucks_pannel" class="column pt-0 px-0 is-2">
        <p class="is-size-4 has-text-centered">
          {{ $t("trucks") }}
          <span
            @click.prevent="show_trucks_pannel = false"
            class="delete is-pulled-right is-small mt-2"
          ></span>
        </p>
        <trucks-planning :list="selected_warehouse_jobs"></trucks-planning>
      </div>
    </div>
    <div v-else>
      <div class="columns is-centered">
        <div class="column is-half is-flex is-justify-content-center">
          <div class="notification is-warning is-light">
            {{ $t("please_select_warehouse") }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ml-4 p-4 has-text-dark has-background-success-light">
    <i class="fa fa-spinner fa-spin"></i> Loading, please wait...
  </div>
</template>

<script>
import axios from "axios";
import socketMixin from "@/socket-mixin.js";
import permissionMixin from "@/permissions-mixin.js";
import socket from "@/socket.js";
import { EventBus } from "@/event-bus";
export default {
  mixins: [socketMixin, permissionMixin],
  components: {
    "warehouse-materials": () =>
      import("@/components/steps/planning/warehouse_materials.vue"),
    "delivery-jobs": () =>
      import("@/components/steps/planning/delivery-jobs.vue"),
    "trucks-planning": () =>
      import("@/components/steps/planning/trucks_planning.vue"),
  },
  data() {
    return {
      selected_warehouse: {},
      selected_warehouse_jobs: [],
      warehouses_parameters: [],
      selected_warehouse_materials: [],
      selected_stop_jobs_materials: [],
      show_all_materials: false,
      project_trucks_jobs: [],
      proj_trc_jobs_got: false,
      show_trucks_pannel: false,
      direction: true,
      all_project_parameters: [],
      selected_warehouse_key: null,
      show_materials_pannel: true,
      current_warehouse_date: 0,
      isFixed: false,
      temp_jobs: [],
      matt: [],
    };
  },
  computed: {
    warehouses() {
      // This is the list of the warehouses and transit locations that shows as tab in the planning
      var l = {};

      // get the transit locations of the materials of the request job
      this.$parent.jobs.map((jw) => {
        if (jw.Caption.toLowerCase().includes("- request")) {
          jw.materials.map((m) => {
            m.date_amount.map((dm) => {
              if (dm.m_connected_project_id && !l[0 - dm.m_connected_project]) {
                let d = jw.DayTimeOut.split("T");
                let d2 = jw.DayTimeIn.split("T");
                l[0 - dm.m_connected_project] = {
                  Id: dm.m_connected_project_id,
                  ID: 0 - dm.m_connected_project,
                  Caption: dm.m_connected_project_name,
                  jobs: [
                    {
                      Caption: "[TRANSIT-JOB]",
                      details: {
                        departure_date: d[0] + " " + d[1],
                        DayTimeIn: jw.DayTimeIn,
                        DayTimeOut: jw.DayTimeOut,
                        pickup_date: d2[0] + " " + d2[1],
                      },
                      departure_date: d[0] + " " + d[1],
                      DayTimeIn: jw.DayTimeIn,
                      DayTimeOut: jw.DayTimeOut,
                      pickup_date: d2[0] + " " + d2[1],
                      ID: dm.m_connected_project,
                      connected_project: dm.m_connected_project,
                      connected_project_id: dm.m_connected_project_id,
                    },
                  ],
                };
              }
            });
          });
        }
      });
      // then get the warehouses for the list of warehouse jobs
      // add a prefix so it sorts the keys: venues first
      this.$parent.jobs_warehouses.forEach((j) => {
        if (!l["w:" + j.Stock.ID]) {
          l["w:" + j.Stock.ID] = {};
        }
        l["w:" + j.Stock.ID] = j.Stock;
      });
      return l;
    },
    materials_1() {
      var mat = [];
      this.$parent.jobs.map((j) => {
        j.materials.map((m) => {
          m.job_id = j.ID;
          m.warehouse_id = j.Stock.ID;

          mat.push(m);
        });
      });

      return mat;
    },
    selected_warehouse_jobs_materials() {
      let r = [];
      let returning_dates = {};

      if (this.direction) {
        return this.selected_warehouse_jobs;
      } else {
        this.selected_warehouse_jobs.map((wj) => {
          if (!returning_dates["aaaaaa"]) returning_dates["aaaaaa"] = [];

          returning_dates["aaaaaa"].push(wj);
          /// here create group based on DayTimeIn
          let warehouse_job = {
            ...wj,
            truck_jobs: [],
            returning_jobs: [],
            showTruckJobsList: false,
          };
          wj.truck_jobs.forEach((tj) => {
            if (tj.direction == 0) {
              warehouse_job.truck_jobs.push(tj);
            }
          });
          wj.returning_jobs.map((tj) => {
            if (tj.direction == 1) warehouse_job.returning_jobs.push(tj);
          });

          // if(warehouse_job.truck_jobs.length > 0)
          // r.push({ ...warehouse_job, truck_jobs: []})
        });
        Object.keys(returning_dates).map((e) => {
          let j = {};
          returning_dates[e].map((job) => {
            if (!j.ID) j = { ...job, status_id: 3 };
            // What is this??!?!?!?!?!
            else j.materials = [...j.materials.concat(job.materials)];
          });
          let list = [...j.materials]; //.filter(t => t.LoadedAmount > 0)]

          j.materials = this.sumsBy(list, "IdST", "LoadedAmount");
          if (j.materials.length > 0) r.push({ ...j });
        });
        return r;
      }
    },
  },
  async created() {
    this.proj_trc_jobs_got = false;

    this.$document.title = "Planning: " + this.$parent.event_details.Caption;
    if (this.$route.params.id && this.$parent.event_jobs.length == 0) {
      this.$parent.event_jobs = [];
      this.$parent.event_details.Jobs = [];
      this.$parent.currentEvent = this.$route.params.id;
      await this.$parent.getProject();
    }
    //Get truck jobs
    if (this.$parent.currentEvent) {
      //	setTimeout(() => {
      this.get_project_truck_jobs();
      this.get_project_parameters();
      //	}, 1000)
    }
    this.$parent.app_lang = this.$i18n.locale;
    this.$parent.app_route = this.$parent.get_url();

    window.addEventListener("scroll", this.handleScroll);

    // if(this.am_allowed('request.can_read')){ // Check if have permissions can_see
    // } else {
    //     if(this.am_allowed('request.can_read')){
    //         this.$router.push('/request-accept/' + this.$route.params.id)
    //     } else if(this.am_allowed('warehouse.can_read')){
    //         this.$router.push('/request-accept/' + this.$route.params.id)
    //     } else if(this.am_allowed('parameter.can_read')){
    //         this.$router.push('/delivery-parameters/' + this.$route.params.id)
    //     } else if(this.am_allowed('planning.can_read')){
    //         this.$router.push('/delivery-plan/' + this.$route.params.id)
    //     } else {
    //         this.$router.push('/')
    //     }
    // }
  },
  mounted() {
    socket.on("someone said", this.socket_listener);
  },
  beforeDestroy() {
    socket.off("someone said", this.socket_listener);
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    materials_1: {
      deep: true,
      handler: function (newVal) {
        this.matt = newVal;
        //   this.allKeys = newVal
        // debugger; // eslint-disable-line no-debugger
      },
    },

    // direction() {
    // 	setTimeout( () => {
    // 		this.revaluateSelectedWarehouseJobs()
    // 	}, 0)
    // }
  },
  methods: {
    handleScroll() {
      if (window.scrollY >= 210) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    socket_listener(data) {
      let operator =
        '<p class = "has-text-primary-dark">' + " " + data.by.name + "</p>";

      if (data.type == "edit job header") {
        EventBus.$emit("save_dates", {
          departure_date: data.data[0].departure_date,
          pickup_date: data.data[0].pickup_date,
        });

        for (let i = 0; i < this.$parent.jobs.length; i++) {
          if (data.data[0].ej_id == this.$parent.jobs[i].IdJob) {
            this.$parent.jobs[i].departure_date = data.data[0].departure_date;
            this.$parent.jobs[i].pickup_date = data.data[0].pickup_date;
          }
        }

        for (let j = 0; j < this.selected_warehouse_jobs.length; j++) {
          this.selected_warehouse_jobs = [
            ...this.selected_warehouse_jobs.map((j) => {
              if (j.IdJob == data.data[0].ej_id) {
                j = {
                  ...j,
                  departure_date: data.data[0].departure_date,
                  pickup_date: data.data[0].pickup_date,
                };
              }
              return j;
            }),
          ];
        }

        this.$toast(
          "<div>" + "Warehouse job dates changed by" + operator + "</div>",
          "info"
        );
      }
    },
    getNotesMaterial() {
      this.$parent.jobs = [
        ...this.$parent.jobs.map((j) => {
          if (!j.Caption.toLowerCase().includes("- request")) {
            j.materials = [
              ...j.materials.map((jm) => {
                this.$parent.jobs.map((rj) => {
                  if (rj.Caption.toLowerCase().includes("- request")) {
                    rj.materials.map((m) => {
                      if (m.IdST == jm.IdST) {
                        m.date_amount.map((md) => {
                          if (md.warehouse_job == j.ID) {
                            // && md.IdST == m.IdST)
                            jm.previous_notes =
                              md.warehouse_notes + md.request_notes;
                            jm.planning_notes = md.planning_notes;
                            jm.m_guid = md.m_guid;
                          }
                        });
                      }
                    });
                  }
                });

                return jm;
              }),
            ];
          }
          return j;
        }),
      ];
      // return notes
    },
    sumsBy(list, key, sums) {
      let unique_list = [];
      console.log("list", list);
      list.map((e) => {
        // debugger; // eslint-disable-line no-debugger

        let fount = -1;
        unique_list.map((m, idx) => {
          if (m[key] == e[key]) fount = idx;
        });
        if (fount == -1) unique_list.push({ ...e });
        else {
          unique_list[fount][sums] = isNaN(unique_list[fount][sums] + e[sums])
            ? 0
            : parseInt(unique_list[fount][sums]) + parseInt(e[sums]);
          unique_list[fount]["QtyWas"] =
            unique_list[fount]["QtyWas"] + e["QtyWas"];
          unique_list[fount]["QtyLinked"] = isNaN(
            unique_list[fount]["QtyLinked"] + e["QtyLinked"]
          )
            ? 0
            : unique_list[fount]["QtyLinked"] + e["QtyLinked"];
        }
      });
      return unique_list;
    },
    materials_selected_warehouse() {
      var swm = [];
      this.$parent.jobs.map((j) => {
        j.materials.map((m) => {
          //if(true) //( j.Stock.ID == w.ID)
          //{
          m.job_id = j.ID;
          m.job_number = j.Number;
          m.warehouse_id = j.Stock.ID;
          swm.push(m);
          //}
        });
      });
      this.selected_warehouse_materials = swm;
      // return swm
    },
    getMaterialData(m) {
      let mat = {};
      // console.log('material data ', m, this.$parent.materials_data)
      this.$parent.materials_data.forEach((material) => {
        if (material.IdStockType == m.IdST) mat = material;
      });
      return mat;
    },
    was_joined_with(job) {
      let is_job = 0;
      this.selected_warehouse_jobs_materials.map((w) => {
        if (w.IdJob == job.ej_id) is_job = w.IdJob;
      });
      if (is_job == 0) {
        this.selected_warehouse_jobs.map((w) => {
          // console.log(
          //   "joined into: ",
          //   this.$moment(job.end_date).isSame(w.DayTimeIn),
          //   this.$moment(job.end_date),
          //   this.$moment(w.DayTimeIn)
          // );
          // console.log(w, job)
          if (
            w.Stock.ID == job.warehouse_id &&
            w.returning_jobs &&
            this.$moment(job.end_date).isSame(w.DayTimeIn) &&
            w.returning_jobs.length > 0
          ) {
            is_job = job.ej_id;
          }
        });
      } else {
        return is_job;
      }
    },
    revaluateSelectedWarehouseJobs(array_name = "selected_warehouse_jobs") {
      // set other variables for project_jobs

      // this.gets the amounts of the loads in delivers and returns
      let matlist = {};
      let matlist_returns = {};

      this.current_warehouse_date = -1;
      this.$data[array_name] = [
        ...this.$data[array_name].map((j) => {
          console.log("each master warehouse job", j);
          j.hide_completed = true;
          j.JobState = { ID: 1, Caption: "Confirmed" };
          j.collapsed = true;
          let transit_material_list = [];
          if (j.connected_project) {
            this.$parent.jobs.map((js) => {
              if (js.Caption.toLowerCase().includes("- request")) {
                js.materials.map((m) => {
                  m.date_amount.map((dm) => {
                    if (dm.m_connected_project_id == j.connected_project_id) {
                      transit_material_list.push({
                        ...m,
                        ...dm,
                      });

                      console.log("aaa", {
                        ...m,
                      });
                      console.log("bbbbb", {
                        ...dm,
                      });
                    }
                  });
                });
              }
            });
          }
          //   console.log(transit_material_list, "transit");
          //   console.log(j.materials, "materials");

          j.materials = j.connected_project_id
            ? transit_material_list
            : j.materials;
          j.materials.map((mj) => {
            if (mj.linked != null) {
              mj.Caption = mj.linked.material_caption;
              // debugger; // eslint-disable-line no-debugger

              if (!mj.summed) {
                mj.QtyWas = Math.ceil(
                  parseFloat(mj.Qty) / parseFloat(mj.linked.factor)
                );

                mj.IdST = mj.m_linked
                  ? mj.m_linked
                  : parseInt(mj.linked.material_stocktype);
              }
            } else {
              mj.Qty = mj.QtyWas;
            }

            console.log("each material", mj);
            mj.LoadedAmount = 0;
            mj.PlannedAmount = 0;
            mj.PlannedAmountReturn = 0;
            mj.RemainsToReturn = 0;
            mj.ReturnedAmount = 0;
            mj.DepartedAmount = 0;
            mj.checkedInAmount = 0;

            // calculate these amounts from the stops that have materials with warehouse id each one of these
            mj.TransitPlannedAmount = 0;
            mj.TransitLoadedAmount = 0;

            //getting transit amounts from truck job
            var g = "";
            this.project_trucks_jobs.map((tj) => {
              if (!tj.connected_project_id) g = tj.project_id;
            });
            this.project_trucks_jobs.map((tj) => {
              if (tj.direction == 0 && tj.connected_project_id) {
                tj.Qty.map((mm) => {
                  if (mm.IdST == mj.IdST && tj.connected_project_id != g) {
                    mj.TransitPlannedAmount +=
                      tj.status_id == 1 || tj.status_id == 2 ? mm.amount : 0;
                    mj.TransitLoadedAmount += tj.status_id == 2 ? mm.amount : 0;
                  }
                });
              }
            });

            matlist[mj.IdST] = 0;
            return mj;
          });
          j.materials = this.sumsBy(j.materials, "IdST", "LoadedAmount");
          j.materials = this.sumsBy(j.materials, "IdST", "DepartedAmount");

          j.materials.map((mj) => {
            mj.summed = true;
          });

          j.materials.map((mj) => {
            mj.Remains = mj.QtyWas - mj.LoadedAmount;
          });

          j.truck_jobs = [
            ...this.project_trucks_jobs.filter((t) => {
              if (j.connected_project_id) {
                if (t.id == 45) console.log(j, t, "match");
                return (
                  t.connected_project_id == j.connected_project_id &&
                  t.direction == 0
                );
              } else return j.IdJob == t.ej_id && t.direction == 0;
            }),
          ].sort(
            (a, b) =>
              this.$moment(a.details.arrives_at).valueOf() -
              this.$moment(b.details.arrives_at).valueOf()
          );

          j.returning_jobs = [
            ...this.project_trucks_jobs.filter((t) => {
              // TODO this should respect the join based on the checkin date.. so DayTimeIn as was joined
              // (if was) in the return for the current warehouse
              // console.log(this.$parent.event_details.ID, t.connected_project)
              if (t.connected_project_id) {
                // console.log(t.project_id, t.connected_project_id, t.connected_project, this.$parent.event_details.ID, t.org_project)
                if (
                  t.connected_project == this.$parent.event_details.ID &&
                  t.direction == 0 &&
                  this.direction == false &&
                  this.$parent.event_details.ID != t.org_project
                )
                  return true;
              } else if (
                t.project_id == this.$parent.event_details.ID &&
                t.direction == 1 &&
                !t.connected_project
              )
                return true;
              else
                return (
                  // j.Stock.ID == t.warehouse_id &&
                  // j.ID == t.ej_id &&
                  t.direction == 1
                );
            }),
          ].sort((a, b) => {
            // console.log('sort', a.details)
            return (
              this.$moment(a.details.arrives_at).valueOf() -
              this.$moment(b.details.arrives_at).valueOf()
            );
          });

          j.truck_jobs = j.truck_jobs.map((tj) => {
            tj.collapsed = tj.status_id == 2 ? false : true;
            tj.Qty = tj.Qty.map((m) => {
              if (tj.status_id == 1 && m.loaded_amount == 0)
                m.loaded_amount = m.amount;

              j.materials = j.materials.map((mj) => {
                if (mj.IdST == m.IdST) {
                  mj.Remains = parseInt(mj.Remains) - parseInt(m.loaded_amount);
                  mj.Qty = parseInt(mj.Qty) - parseInt(m.amount);
                  if (tj.status_id == 2) {
                    if (tj.details.departed_at) {
                      mj.DepartedAmount =
                        mj.DepartedAmount + parseInt(m.loaded_amount);
                    }
                    mj.PlannedAmount =
                      mj.PlannedAmount + parseInt(m.loaded_amount);
                  } else
                    mj.PlannedAmount = mj.PlannedAmount + parseInt(m.amount);

                  if (!matlist[mj.IdST]) matlist[mj.IdST] = 0;
                  matlist[mj.IdST] += parseFloat(m.loaded_amount);
                }
                return mj;
              });
              return m;
            });

            tj.edit_mode = true;

            /*
          tj.details.departs_at ?
            tj.details.departs_at = this.convert_date(tj.details.departs_at) :
            tj.details.departs_at = null

          tj.details.arrives_at ?
            tj.details.arrives_at = tj.details.arrives_at //this.convert_date(tj.details.arrives_at)
            : tj.details.arrives_at = null
          */

            // tj.collapsed = tj.collapsed ? tj.collapsed : true

            return tj;
          });

          j.returning_jobs = j.returning_jobs.map((tj) => {
            tj.collapsed = tj.status_id == 2 ? false : true;
            tj.Qty = tj.Qty.map((m) => {
              // j.materials = this.sumsBy(j.materials, "IdST", "LoadedAmount");

              j.materials = j.materials.map((mj) => {
                if (mj.IdST == m.IdST) {
                  if (!matlist_returns["m" + mj.IdST])
                    matlist_returns["m" + mj.IdST] = 0;
                  matlist_returns["m" + mj.IdST] += parseFloat(m.loaded_amount);
                  if (tj.status_id == 2) {
                    // console.log(tj, 'tj');
                    mj.PlannedAmountReturn =
                      mj.PlannedAmountReturn + parseInt(m.loaded_amount);
                  } else if (tj.status_id == 2)
                    mj.PlannedAmountReturn =
                      mj.PlannedAmountReturn + parseInt(m.amount);
                }
                return mj;
              });
              return m;
            });
            // console.log(
            //   "total returning jobs ",
            //   j.returning_jobs.length,
            //   matlist_returns
            // );

            tj.edit_mode = true;

            /*
          tj.details.departs_at ?
            tj.details.departs_at = this.convert_date(tj.details.departs_at) :
            tj.details.departs_at = null

          tj.details.arrives_at ?
            tj.details.arrives_at = this.convert_date(tj.details.arrives_at)
            : tj.details.arrives_at = null
          */

            // tj.collapsed = tj.collapsed ? tj.collapsed : true

            return tj;
          });

          j.materials = j.materials.map((mj) => {
            mj.LoadedAmount = matlist[mj.IdST];
            mj.LoadedAmount = mj.LoadedAmount ? mj.LoadedAmount : 0;
            mj.ReturnedAmount = matlist_returns["m" + mj.IdST];
            mj.ReturnedAmount = mj.ReturnedAmount ? mj.ReturnedAmount : 0;
            // debugger; // eslint-disable-line no-debugger

            console.log("joooob ", mj);
            // mj.TransitPlannedAmount = j.connected_project ? mj.PlannedAmount : 0
            // mj.TransitLoadedAmount = j.connected_project ? mj.LoadedAmount : 0
            //mj.RemainsToReturn = parseInt(mj.LoadedAmount) - parseInt(mj.ReturnedAmount)
            return mj;
          });

          // j.collapsed = j.truck_jobs.length > 0 ? true : false

          j.showTruckJobsList = false;
          j.truck_jobs_completed = 0;
          j.truck_jobs.map((e) => {
            if (e.status_id == 2)
              j.truck_jobs_completed = j.truck_jobs_completed + 1;
          });

          j.returning_jobs_completed = 0;
          j.returning_jobs.map((e) => {
            if (e.status_id == 2)
              j.returning_jobs_completed = j.returning_jobs_completed + 1;
          });

          return j;
        }),
      ];

      // console.log(this.$data[array_name], "ketu");
    },
    select_warehouse(w) {
      console.log(this.$parent);
      if (this.$parent.are_jobs_loaded) {
        this.selected_warehouse = [];
        this.selected_warehouse_jobs = [];
        this.selected_warehouse_materials = [];
        this.selected_warehouse = this.warehouses[w];
        this.selected_warehouse_key = w;
        // Get jobs of selected_warehouse
        if (this.direction) {
          if (this.selected_warehouse.ID < 0) {
            // it is a transit
            this.selected_warehouse_jobs = [...this.selected_warehouse.jobs];
          } else
            this.selected_warehouse_jobs = [
              ...this.$parent.jobs.filter((e) => {
                // console.log('eeeee', e, 'ware', this.selected_warehouse)
                return (
                  e.Stock.ID == this.selected_warehouse.ID &&
                  !e.Caption.toLowerCase().includes("- request") && // exclude the request job
                  e.Caption.toLowerCase().includes("- w:")
                ); // allow only the warehouse job
              }),
            ].sort(this.$parent.sortDates);
        } else {
          // add here also a job that acts like a warehouse job.. with all the materials that are comming from transits
          // but also the amount was checked in (to be defined as you plan "upfront?!?" with the warehouses we let also not yet onsite... TBD)

          let transit_materials = [];
          let DayTimeIn = "";
          let DayTimeOut = "";
          let d = "";
          let d2 = "";

          // gather all the transit materials from the request
          this.$parent.jobs.map((jssss) => {
            if (jssss.Caption.toLowerCase().includes("- request")) {
              d = jssss.DayTimeOut.split("T");
              d2 = jssss.DayTimeIn.split("T");
              DayTimeIn = jssss.DayTimeIn;
              DayTimeOut = jssss.DayTimeOut;
              jssss.materials.map((mattt) => {
                mattt.date_amount.map((da) => {
                  if (da.m_connected_project_id) {
                    let a = { ...mattt };
                    a.date_amount = [da];
                    a.QtyWas = da.QtyWas;
                    a.Qty = a.QtyWas;
                    console.log("here we have the twenty " + a.QtyWas);
                    transit_materials.push(a);
                  }
                });
              });
            }
          });

          this.selected_warehouse_jobs = [
            ...this.$parent.jobs.filter((e) => {
              return (
                !e.Caption.toLowerCase().includes("- request") && // exclude the request job
                e.Caption.toLowerCase().includes("- w:")
              ); // allow only the warehouse job
            }),
            ...[
              {
                ID: 0,
                Caption: "Transit Not Real Job",
                materials: transit_materials,
                details: {
                  departure_date: d[0] + " " + d[1],
                  DayTimeIn: DayTimeIn,
                  DayTimeOut: DayTimeOut,
                  pickup_date: d2[0] + " " + d2[1],
                },
              },
            ],
          ].sort(this.$parent.sortDates);

          // set other variables for project_jobs
        }

        this.revaluateSelectedWarehouseJobs();

        this.materials_selected_warehouse(this.selected_warehouse);

        this.getWarehouseJobDetails();

        this.getNotesMaterial();
      }
    },
    convert_date(d) {
      if (d) {
        // d = new Date(d)
        d = this.$moment(d).toDate();

        return d; //new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000) // Date(material.start_date)
      }
    },
    get_project_parameters() {
      axios
        .post(this.$tetris_server + "/get/project/warehouse/parameters", {
          project_id: this.$parent.currentEvent,
        })
        .then((r) => {
          this.all_project_parameters = r.data;
          this.warehouses_parameters = r.data.map((d) => {
            d.value = this.getPreviewFields(JSON.parse(d.form_value), "");
            return d;
          });
        });
    },

    getPreviewFields(fields, prefix) {
      let r = "";
      fields.map((f) => {
        if (f.preview == true)
          r += (f.value ? f.value : this.$t("missing_value_general")) + ", ";
        return f;
      });
      return r == ""
        ? /*fields[0].value*/ ""
        : prefix + r.substring(0, r.length - 2);
    },
    collapse_job(c, j) {
      this.selected_warehouse_jobs = [
        ...this.selected_warehouse_jobs.map((o) => {
          if (o.ID == j.ID) {
            o[c] = !o[c];
          }
          return o;
        }),
      ];
    },
    open_collapse_job(c, j) {
      this.selected_warehouse_jobs = [
        ...this.selected_warehouse_jobs.map((o) => {
          if (o.ID == j.ID) {
            o[c] = true;
          }
          return o;
        }),
      ];
    },
    invalidateSelectedWarehouseJobs() {
      let temp = [...this.selected_warehouse_jobs];
      this.selected_warehouse_jobs = [];
      setTimeout(() => {
        this.selected_warehouse_jobs = [...temp];
      }, 0);
    },
    get_project_truck_jobs(evaluate) {
      axios
        .post(this.$tetris_server + "/get/truck/jobs", {
          project_id: this.$parent.currentEvent,
        })
        .then((res) => {
          this.project_trucks_jobs = [
            ...res.data.map((e) => {
              e.edit_mode = true;
              e.details.arrives_at_original = e.details.arrives_at;
              e.details.departs_at_original = e.details.departs_at;
              // e.was_departed = 0;
              return e;
            }),
          ];
          // console.log(this.project_trucks_jobs, res.data, "truck jobs")
          if (evaluate) {
            this.revaluateSelectedWarehouseJobs();
          }

          this.proj_trc_jobs_got = true;
        });
    },
    getWarehouseJobDetails() {
      // Get projects_warehouse_jobs (departure - pickup date)
      axios
        .post(this.$tetris_server + "/all/project/warehouse/jobs", {
          project_id: this.$parent.currentEvent,
        })
        .then((r) => {
          this.$parent.jobs = [
            ...this.$parent.jobs.map((j) => {
              r.data.map((dd) => {
                if (j.IdJob == dd.ej_id) {
                  j = {
                    ...j,
                    departure_date: dd.departure_date,
                    pickup_date: dd.pickup_date,
                  };
                }
              });
              return j;
            }),
          ];
          this.selected_warehouse_jobs = [
            ...this.selected_warehouse_jobs.map((j) => {
              r.data.map((dd) => {
                if (j.IdJob == dd.ej_id) {
                  j = {
                    ...j,
                    departure_date: dd.departure_date,
                    pickup_date: dd.pickup_date,
                  };
                }
              });
              return j;
            }),
          ];
          this.projects_warehouse_jobs_data = [...r.data];

          this.revaluateSelectedWarehouseJobs();
        });
    },
    truck_job_edit_mode(j, em) {
      // console.log('this.selected_warehouse_jobs', this.selected_warehouse_jobs)
      // console.log('j', j)

      this.selected_warehouse_jobs = [
        ...this.selected_warehouse_jobs.map((o) => {
          o.truck_jobs.map((tj) => {
            if (tj.id == j.id) {
              tj.edit_mode = em;
              // console.log('tj.edit_mode', tj.edit_mode)
            }
            return tj;
          });

          o.returning_jobs.map((tj) => {
            if (tj.id == j.id) {
              tj.edit_mode = em;
              // console.log('tj.edit_mode', tj.edit_mode)
            }
            return tj;
          });

          return o;
        }),
      ];
    },
    collapse_truck_job(tjid) {
      // console.log('collapse_truck_job', tjid)
      let fount = false;
      this.selected_warehouse_jobs = [
        ...this.selected_warehouse_jobs.map((o) => {
          o.truck_jobs = [
            ...o.truck_jobs.map((tj) => {
              if (tj.guid == tjid) {
                tj.collapsed = !tj.collapsed;
                fount = true;
              }
              return tj;
            }),
          ];
          if (fount == false)
            o.returning_jobs = [
              ...o.returning_jobs.map((tj) => {
                if (tj.guid == tjid) {
                  tj.collapsed = !tj.collapsed;
                  fount = true;
                }
                return tj;
              }),
            ];

          return o;
        }),
      ];
    },
    update_projects_warehouse_jobs(a) {
      // console.log('update $parent.jobs', a, this.$parent.jobs)
      this.$parent.jobs = [
        ...this.$parent.jobs.map((job) => {
          if (job.IdJob == a[0].ej_id) {
            if (a[0].departure_date != null)
              job.departure_date = a[0].departure_date;
            else job.departure_date = null;
            if (a[0].pickup_date != null) job.pickup_date = a[0].pickup_date;
            else job.pickup_date = null;
          }
          return job;
        }),
      ];

      this.selected_warehouse_jobs = [
        ...this.selected_warehouse_jobs.map((j) => {
          if (j.IdJob == a[0].ej_id) {
            j = {
              ...j,
              departure_date: a[0].departure_date,
              pickup_date: a[0].pickup_date,
            };
          }
          return j;
        }),
      ];
      socket.emit("tell others", {
        type: "edit job header",
        data: a,
      });
    },
  },
};
</script>

<style>
.deliver-plan-right table {
  background: transparent;
}

.deliver-plan-right table td {
  padding: 5px;
}

.deliver-plan-left {
  border: 1px solid #000;
  background: #ebfcfc;
  padding: 0;
}

.material-heading {
  border: solid 1px #000;
  border-width: 1px 0px !important;
}

.ware-name-heading {
  margin-top: 5px;
  border-bottom: solid 2px #000;
}

.planning-ware-block {
  margin-bottom: 20px;
}

/* Warehouse tabs */

.warehouse-planing-tab {
  flex-wrap: wrap;
  width: 135px;
  min-height: 130px;
  /* padding: 3px 10px; */
  /* text-align: center; */
}

.warehouse-planing-tab:hover {
  background: #f3f3f3;
}

.warehouses-taps-list > .active-warehouse-tab {
  background: #f3f3f3;
  border-color: #989898d1;
}

.warehouses-taps-wrap {
  padding: 3px 10px;
}

.job-collapse {
  padding: 5px 8px;
  background: #f3f3f3;
  border: 1px solid #e4e4e4;
  box-shadow: inset 0px 0 10px #e4e4e4;
}

.warehouse-tab-head {
  border-bottom: solid 1px #dbdbdb;
}

.warehouse-tab-footer {
  border-top: solid 1px #dedede;
}

.custom_scrollbar {
  height: 70vh;
  overflow-y: scroll;
}

.custom_scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 20cm;
  background: rgba(255, 166, 0, 0);
}

.custom_scrollbar::-webkit-scrollbar-thumb {
  background: #3e8ed0;
  border-radius: 10px;
}

.truck-job-item {
  /* transition: border-width 0.2s linear; */
  border: solid 1px #dbdbdb;
}

.truck-job-item:hover {
  border-color: #005896;
}

.fixed {
  position: fixed;
  top: 2px;
  width: inherit;
  padding-right: 8px;
}

.left-materials-table {
  width: 99%;
}

.left-materials-table tbody tr {
  color: black;
  border-bottom: 1px solid #f3f3f3;
  background: white !important;
}

.left-materials-table thead tr td {
  background: #f3f3f3 !important;
  color: #000 !important;
}

.planning-warehouses-tabs .is-active a {
  color: #fff !important;
  font-weight: 700;
}
</style>
